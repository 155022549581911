/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:ff0c53a5-d9c1-4ec3-8383-3b44b305fc0e",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_PBtV0P86b",
    "aws_user_pools_web_client_id": "21gharr9mt1vehqvegk171h8i2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://n4bp2uw2rfbqvpz3z3yc4t6oji.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2uey6v5unjagvl5w26mpd25f3m",
    "aws_user_files_s3_bucket": "amplypsy49d4dce3c4594a5a928bf92336dd1f13214616-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
